import { repositoryFactory } from "@/repository/repositoryFactory";
import store from "@/store/index.js";

// 未使用とする 2025.02.01

const utilTemplate = {
  // templatesテーブル及びcontentFormatsテーブルのデータを読み込む
  // content_format_ids: コンテンツフォーマットIDの配列
  async setStore(content_format_ids = null) {
    const TemplateRepository = await repositoryFactory.get("templates");
    const contentFormatsRepository = await repositoryFactory.get(
      "contentFormats"
    );

    // ローディング表示
    store.dispatch("loadingIcon/showIcon");

    store.dispatch("templates/clear");
    await TemplateRepository.list(store.getters["shops/currentShop"].id)
      .then((response) => {
        if (response.data) {
          store.dispatch("templates/setTemplateList", response.data);
        }
      })
      .catch((error) => {
        throw "ERROR:データ取得エラー (" + error + ")";
      });

    // コンテンツフォーマット取得
    await contentFormatsRepository
      .list()
      .then((response) => {
        if (response.data) {
          if (content_format_ids === null) {
            store.dispatch("templates/setContentFormatList", response.data);
          } else {
            const contentFormatList = [];
            Object.keys(response.data).forEach(function (key) {
              if (content_format_ids.includes(response.data[key].id)) {
                contentFormatList.push(response.data[key]);
              }
            });
            store.dispatch("templates/setContentFormatList", contentFormatList);
          }
        }
      })
      .catch((error) => {
        throw (
          "ERROR:templateDialogComponent.vue/init contentFormatsRepository.list (" +
          error +
          ")"
        );
      });

    store.dispatch("loadingIcon/hideIcon"); // ローディング非表示
    return true;
  },
  // templatesテーブルのデータ1件削除する
  async deleteStore(id) {
    const TemplateRepository = await repositoryFactory.get("templates");
    // ローディング表示
    store.dispatch("loadingIcon/showIcon");
    // データ削除処理(is_activeをfalseに)
    const result = await TemplateRepository.delete(id)
      .then((response) => {
        // 更新OK
        return true;
      })
      .catch((error) => {
        throw (
          "ERROR:ShopSite.vue/deleteItem ShopSitesRepository.delete (" +
          error +
          ")"
        );
      })
      .finally(() => {
        store.dispatch("loadingIcon/hideIcon"); // ローディング非表示
      });

    if (result) await store.dispatch("templates/deleteTemplate", id);
    return result;
  },
};

export default utilTemplate;
