<template>
  <div>
    <!-- 更新間隔指定エリア -->
    <div class="v-tab--active mb-2 pb-4">
      <v-subheader class="">
        更新間隔を入力してください。{{ min_interval }}分～{{
          max_interval
        }}分の間で指定可能です。
      </v-subheader>
      <ValidationObserver ref="observerInterval">
        <ValidationProvider
          v-slot="{ errors, valid }"
          name="更新間隔(分)"
          :rules="`required|max:4|between:` + min_interval + `,` + max_interval"
        >
          <v-text-field
            v-model="tabItems[0].interval_minute"
            name="name"
            :error-messages="errors"
            :success="valid"
            suffix="分"
            outlined
            dense
            class="pl-4"
            style="width: 120px"
            background-color="white"
            hide-details
          ></v-text-field>
        </ValidationProvider>
      </ValidationObserver>
    </div>
    <!-- テンプレート選択タブ -->
    <v-tabs
      v-model="tab_items"
      height="0"
      color="teal"
      hide-slider
      v-show="tabIsShow"
    >
      <v-tabs-slider></v-tabs-slider>
      <v-tab v-for="tab in tabItems" :key="tab.name" v-show="tab.show">
        <v-icon v-if="tab.icon" dense class="mr-1">{{ tab.icon }}</v-icon
        >{{ tab.name }}
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab_items" touchless v-show="tabIsShow">
      <v-tab-item v-for="tab in tabItems" :key="tab.name" eager>
        <v-subheader class="v-tab--active">
          指定したテンプレートを一定間隔で繰り返して、更新します。
        </v-subheader>
        <template v-if="tab.index != 999">
          <component
            v-bind:is="tab.component"
            :ref="`set_time_com` + tab.index"
            :child_update_header_id="tab.id"
            :site_content="props.site_content"
            :tab_index="tab.tab_index"
            :mode="setting.mode_timer"
            :child_update_contents="tab.child_update_contents"
            :template_list="props.template_list"
          ></component>
        </template>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import {
  set,
  ref,
  reactive,
  toRefs,
  defineComponent,
} from "@vue/composition-api";
import { repositoryFactory } from "@/repository/repositoryFactory";
import store from "@/store/index.js";
import setting from "@/common/setting.js";
import setDetailComponent from "./setDetail.vue";
import setTimeCogComponent from "./setTimeCog.vue";

export default defineComponent({
  components: {
    setDetailComponent,
    setTimeCogComponent,
  },
  props: [
    "use_template",
    "child_content_header",
    "site_content",
    "template_list",
  ],
  setup(props, ctx) {
    const childUpdateContentsRepository = repositoryFactory.get(
      "childUpdateContents"
    );
    const childUpdateHeaderRepository =
      repositoryFactory.get("childUpdateHeaders");
    const state = reactive({
      useTemplate: null,
      tabItems: [],
      interval: null,
      min_interval: 0,
      max_interval: 1440,
      tabIsShow: true,
    });
    const tab_items = ref();
    const tabList = [];

    const getTabText = (post_year, post_month, post_day, post_week) => {
      let text = "";
      if (post_year != null) {
        text = text + post_year + "年";
      }
      if (post_month != null) {
        text = text + post_month + "月";
      }
      if (post_day != null) {
        text = text + post_day + "日";
      }
      if (post_week != null) {
        text =
          text == ""
            ? setting.weekday[post_week] + "曜日"
            : text + " [" + setting.weekday[post_week] + "]";
      }
      if (text == "") {
        text = "基本設定";
      }
      return text;
    };

    // 空タブを追加する indexが-1の時、末尾に1行追加する
    const addTab = (index) => {
      if (index == -1) {
        index = tabList.length;
      }
      tabList.push({
        id: 0,
        update_content_id: null,
        mode: setting.mode_timer,
        interval_minute: state.min_interval,
        tab_index: index,
        post_year: null,
        post_month: null,
        post_day: null,
        post_week: null,
        config: "",
        index: index,
        name: getTabText(null, null, null, null),
        component: setDetailComponent,
        show: true,
        icon: "",
      });
      return index;
    };

    const setTab = (data) => {
      // child_update_headerに関する内容
      set(state.tabItems[data.tab_index], "id", data.id);
      set(
        state.tabItems[data.tab_index],
        "update_content_id",
        data.update_content_id
      );
      set(state.tabItems[data.tab_index], "mode", data.mode);
      set(
        state.tabItems[data.tab_index],
        "interval_minute",
        data.interval_minute
      );
      set(state.tabItems[data.tab_index], "tab_index", data.tab_index);
      set(state.tabItems[data.tab_index], "post_year", data.post_year);
      set(state.tabItems[data.tab_index], "post_month", data.post_month);
      set(state.tabItems[data.tab_index], "post_day", data.post_day);
      set(state.tabItems[data.tab_index], "post_week", data.post_week);
      set(state.tabItems[data.tab_index], "config", data.config);
      set(
        state.tabItems[data.tab_index],
        "child_update_contents",
        data.child_update_contents
      );
    };

    const setData = async () => {
      // 更新間隔
      state.min_interval = props.site_content.min_interval;
      // if (props.child_content_header.length != 0) {
      //   state.interval = props.child_content_header[0].interval_minute;
      // } else {
      //   state.interval = props.site_content.min_interval;
      // }

      // テンプレートを指定しないコンテンツの場合、行を表示しない
      state.tabIsShow = props.use_template;

      // 基本設定タブのみ
      addTab(0);
      // Tab生成
      for (let i = 0; i < tabList.length; i++) {
        state.tabItems.push(tabList[i]);
      }

      const cuc = props.child_content_header;
      Object.keys(cuc).forEach(function (key) {
        setTab(cuc[key]);
      });
    };

    const init = async () => {
      await setData();
    };

    init();

    // 保存前のチェック
    const checkPreSave = async () => {
      // 更新間隔チェック
      const isValid = await ctx.refs.observerInterval.validate();
      if (!isValid) {
        alert("更新間隔の入力に誤りがあります。");
        return false;
      }

      // タブ毎(1個のみ)に保存前のチェックを実施していく
      for (let i = 0; i < state.tabItems.length; i++) {
        const tab_component =
          ctx.refs["set_time_com" + state.tabItems[i].index][0];
        if (!(await tab_component.checkPreSave())) {
          tab_items.value = state.tabItems[i].index;
          return false;
        }
      }
      if (state.tabIsShow) {
        for (let i = 0; i < state.tabItems.length; i++) {
          const tab_component =
            ctx.refs["set_time_com" + state.tabItems[i].index][0];
          if (tab_component.getTemplateCount() == 0) {
            alert("テンプレートを一つ以上設定してください。");
            return false;
          }
        }
      }
      return true;
    };

    // 保存処理 child_update_headerの更新
    const save = async (saveUpdateContents) => {
      // 一旦、該当のchild_update_headerを全削除する
      const del_params = {
        update_content_id: saveUpdateContents.id,
        mode: setting.mode_timer,
      };
      await childUpdateHeaderRepository
        .deletelist(del_params)
        .then((response) => {
          return true;
        })
        .catch((error) => {
          throw (
            "ERROR:setTime.vue/save childUpdateContentsRepository.deletelist (" +
            error +
            ")"
          );
        });

      // 表示中のタブ(1個のみ)を新規追加
      for (let i = 0; i < state.tabItems.length; i++) {
        const createParam = {
          "update_content_id": saveUpdateContents.id,
          "mode": setting.mode_timer,
          "tab_index": state.tabItems[i].index,
          "interval_minute": state.tabItems[i].interval_minute,
        };
        const child_update_header_id = await childUpdateHeaderRepository
          .create(createParam)
          .then((response) => {
            return response.data.id;
          })
          .catch((error) => {
            throw (
              "ERROR:tabSpecify.vue/save childUpdateHeaderRepository.create (" +
              error +
              ")"
            );
          });
        state.tabItems[i].id = child_update_header_id;
      }
      // 子コンポーネントから保存内容を取得しておき、まとめて保存する
      let child_update_contents = [];
      for (let i = 0; i < state.tabItems.length; i++) {
        const tab_component =
          ctx.refs["set_time_com" + state.tabItems[i].index][0];
        const saveList = tab_component.save(state.tabItems[i].id);
        if (saveList.length > 0) {
          child_update_contents = child_update_contents.concat(saveList);
        }
      }
      // 一括保存処理
      await childUpdateContentsRepository
        .save({ child_update_content: child_update_contents })
        .then((response) => {
          return true;
        })
        .catch((error) => {
          throw (
            "ERROR:setTime.vue/save childUpdateContentsRepository.save (" +
            error +
            ")"
          );
        });

      return true;
    };

    // modeを返す
    const getMode = () => {
      return setting.mode_timer;
    };

    return {
      setting,
      tab_items,
      props,
      ...toRefs(state),
      setData,
      checkPreSave,
      save,
      getMode,
    };
  },
});
</script>

<style scoped>
.v-tab--active {
  background-color: #e8f5e9;
}
</style>
