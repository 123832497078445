<template>
  <div class="pa-2 backcolor_green_lighten-5">
    <v-form ref="refForm">
      <draggable
        v-model="detailList"
        v-bind="dragOptions"
        handle=".list_num"
        @end="onEnd"
        :force-fallback="true"
      >
        <div v-for="row in detailList" :key="row.index">
          <v-card tile class="time_row d-flex flex-wrap mb-2" :row="row.index">
            <div
              class="list_num flex_center pa-4 backcolor_blue-grey_lighten-5"
            >
              {{ row.index + 1 }}
            </div>
            <div
              class="flex_center px-2 pt-5 pb-3"
              v-show="props.mode == setting.mode_specify"
            >
              <div class="select_item">
                <v-select
                  class="select_hour"
                  v-model="row.post_hour"
                  :items="selectITem.hourItem"
                  item-text="text"
                  item-value="value"
                  label="時"
                  dense
                  hide-details="false"
                  :rules="[rowCheck(row, 'post_hour'), row.rolus]"
                >
                </v-select>
              </div>
              <div
                class="select_item ml-1"
                v-show="props.mode == setting.mode_specify"
              >
                <v-select
                  class="select_minute"
                  v-model="row.post_minute"
                  :items="selectITem.minuteItem"
                  item-text="text"
                  item-value="value"
                  label="分"
                  dense
                  hide-details="false"
                  :rules="[rowCheck(row, 'post_minute'), row.rolus]"
                ></v-select>
              </div>
            </div>
            <div
              class="flex_center select_template px-2 pt-5 pb-3"
              v-show="templateList.length != 0"
            >
              <v-select
                class="select_template_id"
                v-model="row.template_id"
                :items="templateList"
                item-text="name"
                item-value="id"
                label="テンプレート選択"
                no-data-text="テンプレートがありません"
                dense
                hide-details="false"
                :rules="[rowCheck(row, 'template_id'), row.rolus]"
              ></v-select>
            </div>
            <div class="flex_center ml-auto px-2 pt-5 pb-3">
              <v-icon
                class="c_pointer"
                title="削除"
                @click="deleteRow(row.index)"
                >mdi-delete</v-icon
              >
            </div>
          </v-card>
        </div>
      </draggable>
    </v-form>
    <v-app-bar dense flat color="transparent" class="mb-2">
      <v-spacer></v-spacer>
      <v-btn class="cyan font-weight-bold" dark dense @click="addRow(-1)"
        ><v-icon left>mdi-plus</v-icon>行追加</v-btn
      >
    </v-app-bar>
  </div>
</template>

<script>
import {
  set,
  ref,
  reactive,
  toRefs,
  defineComponent,
  watch,
} from "@vue/composition-api";
import _ from "lodash";
import draggable from "vuedraggable";
import store from "@/store/index.js";
import setting from "@/common/setting.js";
import selectITem from "@/common/selectItem.js";

export default defineComponent({
  components: {
    draggable,
  },
  props: [
    "child_update_header_id",
    "site_content",
    "tab_index",
    "child_update_contents",
    "mode",
    "template_list",
  ],
  setup(props, ctx) {
    const refForm = ref();
    const state = reactive({
      detailList: [],
      templateList: [],
    });

    // detailListの値が変更されたとき、値をチェック
    watch(
      () => _.cloneDeep(state.detailList),
      () => {
        return refForm.value.validate();
      }
    );

    // 入力された値のチェック
    const rowCheck = (row, itemName) => {
      // タイマー更新の場合、チェックをスルー
      if (props.mode == setting.mode_timer) {
        return true;
      }

      if (
        row.post_hour == null &&
        row.post_minute == null &&
        row.template_id == null
      ) {
        return true;
      }

      let result = false;
      // 必須入力
      switch (itemName) {
        case "post_hour":
          result = !(
            (row.post_minute != null || row.template_id != null) &&
            row.post_hour == null
          );
          break;
        case "post_minute":
          result = !(
            (row.post_hour != null || row.template_id != null) &&
            row.post_minute == null
          );
          break;
        case "template_id":
          if (state.templateList.length != 0) {
            result = !(
              (row.post_hour != null || row.post_minute != null) &&
              row.template_id == null
            );
          } else {
            result = true;
          }
          break;
        default:
      }
      if (!result) {
        return result;
      }

      // 同時刻チェック
      switch (itemName) {
        case "post_hour":
        case "post_minute":
          if (row.post_hour != null && row.post_minute != null) {
            const array_result = state.detailList.filter((array) => {
              if (
                array.post_hour != null &&
                array.post_minute != null &&
                array.post_hour == row.post_hour &&
                array.post_minute == row.post_minute
              ) {
                return true;
              }
            });
            if (array_result.length >= 2) {
              result = false;
            }
          }
          break;
        default:
      }
      if (!result) {
        return result;
      }
      return result;
    };

    // 件数を返す
    const getDetailCount = () => {
      return state.detailList.length;
    };

    // 件数を返す
    const getTemplateCount = () => {
      return state.detailList.filter((v) => v.template_id != null).length;
    };

    // 保存前のチェック
    const checkPreSave = async () => {
      // タイマー更新の場合、チェックをスルー
      if (props.mode == setting.mode_timer) {
        state.detailList.sort((a, b) => {
          if (a.template_id === null) {
            return 1;
          }
          if (b.template_id === null) {
            return -1;
          }
          return 0;
        });
        // sort番号振りなおす
        numbering();
        return true;
      }

      // 時、分にて昇順に並び変える
      state.detailList.sort((a, b) => {
        if (a.post_hour === null || a.post_minute === null) {
          return 1;
        }
        if (b.post_hour === null || b.post_minute === null) {
          return -1;
        }
        // if (a.post_hour == null || a.post_minute == null) return 1;
        if (a.post_hour > b.post_hour) return 1;
        if (a.post_hour < b.post_hour) return -1;
        if (a.post_minute > b.post_minute) return 1;
        if (a.post_minute < b.post_minute) return -1;
        return 0;
      });
      // sort番号振りなおす
      numbering();

      // 時刻指定が0件の場合
      if (state.detailList.length == 0) {
        return true;
      }

      // 入力チェック
      if (!refForm.value.validate()) {
        alert(
          "時刻指定に誤りがあります。（必須入力漏れまたは同一時刻の指定等、確認してください）"
        );
        return false;
      }

      // props.site_content.min_intervalより時間が小さい間隔だったらエラー
      for (let i = 0; i + 1 < state.detailList.length; i++) {
        const a = i;
        const b = i + 1;
        if (state.detailList[b].post_hour != null) {
          const a_date = new Date();
          const b_date = new Date();
          a_date.setHours(state.detailList[a].post_hour);
          a_date.setMinutes(state.detailList[a].post_minute);
          b_date.setHours(state.detailList[b].post_hour);
          b_date.setMinutes(state.detailList[b].post_minute);
          var diff = b_date.getTime() - a_date.getTime();
          // console.log(a + " " + b + " " + Math.floor(diff / (1000 * 60)));
          // console.log("min_interval", props.site_content.min_interval);
          if (
            Math.floor(diff / (1000 * 60)) < props.site_content.min_interval
          ) {
            const errorTime = state.detailList.find(
              (value) => value.index == state.detailList[b].index
            );
            alert(
              state.detailList[a].index +
                1 +
                "番目の" +
                state.detailList[a].post_hour +
                "時" +
                state.detailList[a].post_minute +
                "分と\n" +
                (state.detailList[b].index + 1) +
                "番目の" +
                state.detailList[b].post_hour +
                "時" +
                state.detailList[b].post_minute +
                "分は\n" +
                "最低更新間隔より小さい間隔を指定しています。設定時刻を見直してください。" +
                props.mode
            );
            return false;
          }
        }
      }
      return true;
    };

    // 保存処理(親コンポーネントで一括保存するため、保存するデータを返す)
    const save = (child_update_header_id) => {
      // tabSpecify.vueにて更新の場合でもデータが削除されているので、すべて新規追加する
      const saveList = [];
      let sort = 0;
      state.detailList.forEach((detail, index) => {
        if (
          (props.mode == setting.mode_specify &&
            detail.post_hour != null &&
            detail.post_minute != null) ||
          (props.mode == setting.mode_timer && detail.template_id != null)
        ) {
          detail.child_update_header_id = child_update_header_id;
          detail.sort = sort;
          saveList.push(detail);
          sort++;
        }
      });
      return saveList;
    };

    // 空行を追加する indexが-1の時、末尾に1行追加する
    const addRow = (index) => {
      if (index == -1) {
        numbering();
        index = state.detailList.length;
      }
      state.detailList.push({
        index: index,
        id: 0,
        child_update_header_id: 0,
        sort: index,
        post_hour: null,
        post_minute: null,
        template_id: null,
        config: "",
        rolus: true,
      });
    };

    const setRow = (index, data) => {
      if (state.detailList.length <= index) {
        addRow(index);
      }
      set(state.detailList[index], "index", index);
      set(state.detailList[index], "id", data.id);
      set(
        state.detailList[index],
        "child_update_header_id",
        data.child_update_header_id
      );
      set(state.detailList[index], "sort", data.sort);
      set(state.detailList[index], "post_hour", data.post_hour);
      set(state.detailList[index], "post_minute", data.post_minute);
      set(state.detailList[index], "template_id", data.template_id);
      set(state.detailList[index], "config", data.config);
      set(state.detailList[index], "rolus", true);
    };

    const deleteRow = (index) => {
      if (!window.confirm("この行を削除してよろしいですか？")) {
        return false;
      }
      state.detailList.splice(index, 1);
      numbering();
    };

    // 削除・並び替え時にindexとsortを振りなおす
    const numbering = () => {
      for (let index = 0; index < state.detailList.length; index++) {
        set(state.detailList[index], "sort", index);
        set(state.detailList[index], "index", index);
      }
    };

    const onEnd = () => {
      numbering();
    };

    const setData = async () => {
      // テンプレートリスト取得
      // state.templateList.splice(0);
      // if (props.site_content.content_format_ids.length > 0) {
      //   state.templateList.push({ id: null, name: "--" });
      //   let templates = await store.getters["templates/getTemplateList"];
      //   templates.forEach(function (template, index) {
      //     if (
      //       props.site_content.content_format_ids.includes(
      //         template.content_format_id
      //       )
      //     ) {
      //       state.templateList.push({ id: template.id, name: template.name });
      //     }
      //   });
      // }
      state.templateList = props.template_list;

      // からっぽの時刻リストを作る
      state.detailList.splice(0);
      const row_num =
        props.tab_index == 0
          ? setting.default_update_rows
          : setting.default_update_rows_other;

      // 読み込んでいるデータを行にセット
      if (props.child_update_contents) {
        for (let i = 0; i < props.child_update_contents.length; i++) {
          setRow(i, props.child_update_contents[i]);
        }
      }
      // 初期表示の行より少ないとき、空行を足す
      for (let index = state.detailList.length; index < row_num; index++) {
        addRow(index);
      }
    };

    const init = async () => {
      await setData();
    };

    init();

    return {
      dragOptions: {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost",
      },
      drag: false,
      onEnd,
      store,
      setting,
      selectITem,
      refForm,
      props,
      ...toRefs(state),
      setData,
      getDetailCount,
      getTemplateCount,
      checkPreSave,
      save,
      deleteRow,
      addRow,
      rowCheck,
    };
  },
});
</script>

<style scoped>
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
.hide_item {
  display: none !important;
}
.backcolor_green_lighten-5 {
  background-color: #e8f5e9;
}
.backcolor_blue-grey_lighten-5 {
  background-color: #eceff1;
}
.backcolor_red_lighten {
  background-color: #ffefef;
}
.c_pointer {
  cursor: pointer;
}
.flex_center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.list_num {
  width: 3em;
  text-align: center;
  cursor: move;
}
.select_item {
  width: 4em;
}
.select_template {
  width: 17em;
}
</style>
